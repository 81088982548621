@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
/* medium */
@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/1475532/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2')
      format('woff2'),
    url('/assets/fonts/1475532/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff')
      format('woff');
  font-weight: 400;
}
/* heavy */
@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/1475544/d513e15e-8f35-4129-ad05-481815e52625.woff2')
      format('woff2'),
    url('/assets/fonts/1475544/61bd362e-7162-46bd-b67e-28f366c4afbe.woff')
      format('woff');
  font-weight: 800;
}
/* black */
@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/1475556/c78eb7af-a1c8-4892-974b-52379646fef4.woff2')
      format('woff2'),
    url('/assets/fonts/1475556/75b36c58-2a02-4057-a537-09af0832ae46.woff')
      format('woff');
  font-weight: 900;
}
/* light */
@font-face {
  font-family: 'Souvenir';
  src: url('/assets/fonts/5728351/a08b6c44-0724-4de4-81c6-0fac850be8a9.woff2')
      format('woff2'),
    url('/assets/fonts/5728351/74686ffa-8d0a-4f8c-af0f-6810428983cc.woff')
      format('woff');
  font-weight: 300;
}

/* Root Colors */
@layer base {
  :root {
    --red: 255 0 68;
    --red-dark: 172, 0, 67;
    --red-light: 255 128 132;
    --plum: 51 5 35;
    --plum-light: 111 64 93;
    --blue: 0 255 255;
    --blue-light: 153 255 255;
    --blue-dark: 0 159 159;
    --white: 255 255 255;
    --clear: transparent;

    --color-fill: rgb(var(--white));

    --color-text-primary: rgb(var(--plum));
    --color-text-secondary: rgb(var(--red));

    --color-button-primary: rgb(var(--plum));
    --color-button-primary-hover: rgb(var(--white));
    --bg-button-primary: rgb(var(--blue));
    --bg-button-primary-hover: rgb(var(--plum));
    --border-button-primary: var(--clear);

    --color-button-secondary: rgb(var(--white));
    --color-button-secondary-hover: rgb(var(--white));
    --bg-button-secondary: rgb(var(--red));
    --bg-button-secondary-hover: rgb(var(--red-light));
    --color-button-tertiary: rgb(var(--red));
    --bg-button-tertiary: rgb(var(--white));

    --border-button-secondary: var(--clear);

    --color-link-icon: rgb(var(--red));
    --color-swiper-icon: rgb(var(--blue));
  }

  /* Themes  */
  .theme-plum {
    --color-fill: rgb(var(--plum));

    --color-text-primary: rgb(var(--white));
    --color-text-secondary: rgb(var(--blue));

    --color-button-primary: rgb(var(--plum));
    --color-button-primary-hover: rgb(var(--plum));
    --bg-button-primary: rgb(var(--blue));
    --bg-button-primary-hover: rgb(var(--blue-dark));
    --border-button-primary: var(--clear);

    --color-button-secondary: rgb(var(--plum));
    --color-button-secondary-hover: rgb(var(--plum));
    --bg-button-secondary: rgb(var(--red));
    --bg-button-secondary-hover: rgb(var(--red-dark));
    --border-button-secondary: var(--clear);

    --color-link-icon: rgb(var(--red));
    --color-swiper-icon: rgb(var(--white));
  }

  .theme-red {
    --color-fill: rgb(var(--red));

    --color-text-primary: rgb(var(--white));
    --color-text-secondary: rgb(var(--plum));

    --color-button-primary: rgb(var(--plum));
    --color-button-primary-hover: rgb(var(--plum));
    --bg-button-primary: rgb(var(--blue));
    --bg-button-primary-hover: rgb(var(--blue-dark));
    --border-button-primary: var(--clear);

    --color-button-secondary: rgb(var(--white));
    --color-button-secondary-hover: rgb(var(--plum));
    --bg-button-secondary: rgb(var(--plum));
    --bg-button-secondary-hover: rgb(var(--white));

    --color-button-tertiary: rgb(var(--red));
    --color-button-tertiary-hover: rgb(var(--white));
    --bg-button-tertiary: rgb(var(--white));
    --bg-button-tertiary-hover: rgb(var(--red));

    --border-button-secondary: rgb(var(--plum));

    --color-link-icon: rgb(var(--plum));
    --color-swiper-icon: rgb(var(--white));
  }

  .theme-blue {
    --color-fill: rgb(var(--blue));

    --color-text-primary: rgb(var(--plum));
    --color-text-secondary: rgb(var(--white));

    --color-button-primary: rgb(var(--white));
    --color-button-primary-hover: rgb(var(--plum));
    --bg-button-primary: rgb(var(--plum));
    --bg-button-primary-hover: rgb(var(--white));
    --border-button-primary: rgb(var(--plum));

    --color-button-secondary: rgb(var(--white));
    --color-button-secondary-hover: rgb(var(--white));
    --bg-button-secondary: rgb(var(--red));
    --bg-button-secondary-hover: rgb(var(--red-dark));
    --border-button-secondary: var(--clear);

    --color-link-icon: rgb(var(--red));
    --color-swiper-icon: rgb(var(--white));
  }
}

* {
  scroll-margin-top: 120px;
}

@media (min-width: 1024px) {
  * {
    scroll-margin-top: 180px;
  }
}

/* Font family formatting on Headings */
h1.font-display-a strong,
h2.font-display-a strong,
h3.font-display-a strong,
h4.font-display-a strong,
h5.font-display-a strong,
h6.font-display-a strong {
  font-family: 'Souvenir', sans-serif;
}

h1.font-display-s strong,
h2.font-display-s strong,
h3.font-display-s strong,
h4.font-display-s strong,
h5.font-display-s strong,
h6.font-display-s strong {
  font-family: 'Avenir', sans-serif;
}

/* Blockquote Override */
.blockquote div {
  margin-bottom: 0;
}
.blockquote span > span,
.blockquote div > * {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.15;
  font-family: 'Souvenir', sans-serif;
  quotes: '“' '“' '‘' '’';
}
.blockquote span > span:before,
.blockquote div > *:before {
  content: open-quote;
}
.blockquote span > span:after,
.blockquote div > *:after {
  content: close-quote;
}
@media (min-width: 1024px) {
  .blockquote span > span,
  .blockquote div > * {
    font-size: 26px;
    line-height: 1.35;
  }
}

/* Un Ordered List */
ul li {
  margin: 16px 0;
}
.list-unordered > li {
  padding-left: 34px;
  position: relative;
}
.list-unordered > li * {
  margin-bottom: 0;
  font-size: 16px;
}
.list-unordered > li:before {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 100%;

  /* SVG background image */
  background-size: 12px 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../public/assets/svg/tick-white.svg');
  background-color: rgb(var(--plum));
}

.list-unordered.list-blue > li:before,
.theme-plum .list-unordered > li:before {
  background-image: url('../public/assets/svg/tick-plum.svg');
  background-color: rgb(var(--blue));
}

/* Custom arrow container */
.clip-container_left,
.clip-container_left_small {
  clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 30% 100%, 0% 50%);
}
.clip-container_left_border,
.clip-container_left_border_small {
  clip-path: polygon(
    calc(30% + -4px) 0%,
    100% 0%,
    100% 100%,
    calc(30% + -4px) 100%,
    0% 50%
  );
}
.clip-container_right,
.clip-container_right_small {
  clip-path: polygon(0% 0%, 70% 0%, 100% 50%, 70% 100%, 0% 100%);
}
.clip-container_right_border,
.clip-container_right_border_small {
  clip-path: polygon(
    0% 0%,
    calc(70% + 4px) 0%,
    100% 50%,
    calc(70% + 4px) 100%,
    0% 100%
  );
}

.clip-container_left_none,
.clip-container_right_none {
  clip-path: none;
}

@media (min-width: 768px) {
  .clip-container_left_small {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 50%);
  }
  .clip-container_left_border_small {
    clip-path: polygon(
      calc(20% + -4px) 0%,
      100% 0%,
      100% 100%,
      calc(20% + -4px) 100%,
      0% 50%
    );
  }
  .clip-container_right_small {
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  }
  .clip-container_right_border_small {
    clip-path: polygon(
      0% 0%,
      calc(80% + 4px) 0%,
      100% 50%,
      calc(80% + 4px) 100%,
      0% 100%
    );
  }
}

@media (min-width: 1024px) {
  .clip-container_left_none {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 20% 100%, 0% 50%);
  }

  .clip-container_right_none {
    clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  }
}

/* Custom arrow button */
.clip-button_right,
.clip-button_left {
  display: block;
  height: 26px;
  width: 26px;
  position: absolute;
  top: calc(50% - 13px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
.clip-button_left {
  left: 6px;
  transform: rotate(45deg);
}
.clip-button_right {
  right: 6px;
  transform: rotate(-135deg);
}

/* Radial for Author INEV10 */
.bg-radial-black {
  background: radial-gradient(
    100.61% 100.61% at 50% 50%,
    rgba(0, 0, 0, 0) 0%,
    #000000 100%
  );
}

/* React Slider - CON11 */
.swiper-con23 .swiper-slide {
  height: auto;
}

/* React Slider - CON11 */
.swiper-con11 .swiper-wrapper {
  align-items: center;
  cursor: grab;
}

.swiper-con11 .swiper-button-next:after,
.swiper-con11 .swiper-button-prev:after {
  display: none;
}

@media only screen and (min-width: 768px) {
  .swiper-con11 .swiper-button-prev,
  .swiper-con11 .swiper-button-next {
    color: var(--color-swiper-icon);
    display: flex;
    width: 21px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    margin-top: -15px;
  }

  .swiper-con11 .swiper-button-next:after,
  .swiper-con11 .swiper-button-prev:after {
    display: block;
    font-size: 21px;
  }
}

.swiper-con11 .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* React Pagination */
.pagination li {
  @apply mx-2 my-0;
}
